<template>
  <Card title="social_shipping.finished_title">
    <template v-if="socialShippingLink.link_track_trace">
      <p
        v-test="'finished_description_track_trace'"
        v-t="'social_shipping.finished_description_track_trace'" />

      <CButton
        :loading="loading"
        @click="navigate">
        {{ $t('social_shipping.to_track_trace') }}
      </CButton>
    </template>

    <p
      v-else
      v-test="'finished_description'"
      v-t="'social_shipping.finished_description'" />
  </Card>
</template>

<script>
import CButton from '@/components/CButton';
import Card from '@/components/Card';
import { mapState } from 'vuex';

export default {
  name: 'SocialShippingFinished',
  components: { CButton, Card },

  data() {
    return {
      iconLeft: null,
      iconRight: 'star-o',
      loading: false,
    };
  },

  computed: {
    ...mapState('socialShipping', ['socialShippingLink']),

    /**
     * Returns the path from the Track & Trace link in the store so it can be used with the router.
     *
     * @returns {String}
     */
    path() {
      const url = this.socialShippingLink.link_track_trace.replace(/\s/g, '');
      return new URL(url).pathname;
    },
  },
  methods: {
    /**
     * Navigate to the Track & Trace link. Append current query to avoid a router error caused by redirecting twice.
     */
    navigate() {
      this.loading = true;

      this.$router.push({
        query: this.$route.query,
        path: this.path,
      });
    },
  },
};
</script>
